import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import ReportCard from './ReportCard';
// import RecommendationCards from './RecommendationCards';
import testingReports from "../../assets/sampleStuffForDevelopmentAid/reportsDataToRenderCards.json";
import testingRecommedationCardsData from "../../assets/sampleStuffForDevelopmentAid/recommendationsDataToRenderCards.json";
import testingTimelineCardsData from "../../assets/sampleStuffForDevelopmentAid/timelineDataToRenderCards.json"
import { useNavigate } from 'react-router-dom';
import { formatToLocalDate, formatTimeStringToDateForTimeline, formatTimelineEndDate } from "../FormattedDate";
import { Flex, Spin } from 'antd';
import NoDataViewComponent from './NoDataView';
import '../../css/TabsComponent.css';

const { Search } = Input;

const TabsComponent = ({ activeTab, setActiveTab, user_details }) => {
    // const [activeTab, setActiveTab] = useState('Reports');
    // console.log(testingReports)

    // checking the user details passed into this component
    console.log("user details passed into this tabs component -- ", user_details);

    // Setting up state for the API data
    // let’s set up state hooks to store the data fetched from each API:
    const [allUserAssessments, setAllUserAssessments] = useState([]);
    const [allRecommendations, setAllRecommendations] = useState([]);
    const [allRemediations, setAllRemediations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            await Promise.all([
                fetchAllUserAssessments(),
                fetchAllRecommendations(),
                fetchAllRemediations()
            ]);
            setLoading(false);
        }
        fetchData();
    }, []);

    const fetchAllUserAssessments = async () => {
        try {
            const response = await fetch(`https://api.anvillist.com/api/v1/all-business-assessments`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                }
            });

            console.log("response from the get all assessment API call ");
            console.log(response);

            const data = await response.json();
            console.log("response data from the get all assessment API call response.json() parsing function");
            console.log(data);

            setAllUserAssessments(data);
        } catch (error) {
            console.error('Error fetching data from API 1 - get all assessment API call', error);
        }
    };

    const fetchAllRecommendations = async () => {
        try {
            const response = await fetch("https://api.anvillist.com/api/v1/get-recommendations", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                }
            });

            console.log("response from the get all recommendations API call ");
            console.log(response);

            const data = await response.json();
            console.log("response data from the get all recommendations API call response.json() parsing function");
            console.log(data);

            setAllRecommendations(data);
        } catch (error) {
            console.error('Error fetching data from API 2 - get all recommendations API call', error);
        }
    };

    const fetchAllRemediations = async () => {
        try {
            const response = await fetch("https://api.anvillist.com/api/v1/business/remediation", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                }
            });

            console.log("response from the get all remediations API call ");
            console.log(response);

            const data = await response.json();
            console.log("response data from the get all remediations API call response.json() parsing function");
            console.log(data);

            setAllRemediations(data);
        } catch (error) {
            console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const onSearch = (value, _e, info) => console.log(info?.source, value);
    const onSearchProfession = (value, _e, info) => console.log(info?.source, value);
    const navigate = useNavigate();

    const renderReports = () => {
        // console.log("all user assessments in this position is - ", allUserAssessments);
        // console.log("all user assessmentd data == ", allUserAssessments.data);
        // console.log("all user assessments data length == ", allUserAssessments.data.length);
        if (allUserAssessments.data.length === 0) {
            return <NoDataViewComponent title="No assessment reports available at this time"/>
        }
        return allUserAssessments.data.map((report, index) => (
            <ReportCard
                key={index}
                title={report.name}
                date={formatToLocalDate(report.createdAt)}
                assessment={report.type}
                actionButtonTitle="For Reports"
            />
        ));
    };

    const renderRecommendations = () => {
        // return testingRecommedationCardsData.map((report, index) => (
        //     <ReportCard
        //         key={index}
        //         title={report.title}
        //         subtitle={report.subtitle}
        //         price={report.price}
        //         assessment={report.assessment}
        //         profileImage={report.profileImage}
        //         actionButtonTitle="For Recommendations"
        //         bookAMeetingButtonClick={handleViewProfessionalDetailButtonClick}
        //     />
        // ));
        if (allRecommendations.data.length == 0) {
            return <NoDataViewComponent title="No recommendations available at this time"/>
        }
        return allRecommendations.data.map((report, index) => (
            <ReportCard
                key={index}
                title={report.rep_business_name}
                subtitle={`Gap fixes for ${report.gap_fix}`}
                price={report.profile.compensation}
                assessment={`Available rep ${report.name}`}
                profileImage={report.name}
                actionButtonTitle="For Recommendations"
                professionalPersonalInformation={report}
            />
        ));
    };

    const renderTimelines = () => {
        if (allRemediations.data.length == 0) {
            return <NoDataViewComponent title="No remediations available at this time"/>
        }
        return allRemediations.data.slice(0, 5).map((report, index) => (
            <ReportCard
                key={index}
                title={report.client}
                subtitle={`Gap fixes for ${report.name}`}
                startDate={formatTimeStringToDateForTimeline(report.createdAt)}
                endDate={formatTimelineEndDate(report.end_date)}
                progressCompleted={report.completion_level}
                profileImage={report.id}
                actionButtonTitle="For Timeline"
            />
        ));
    };

    const handleViewAllTimelinesOptionClick = () => {
        navigate("/remediationTimelines");
    }

    const renderContent = () => {
        if (loading) {
            // return <div>Loading...</div>;
            return (
                <Flex align="center" gap="middle">
                    <Spin size="large" className='spinner-container'/>
                </Flex>
            );
        }
        switch (activeTab) {
            case 'Reports':
                return (
                    <div style={{ width: '100%' }}>
                        {/* <ReportCard /> */}
                        {renderReports()}
                    </div>
                );
            case 'Recommendations':
                // return <div className="content">This is the Recommendations content. </div>;
                return (
                    <div>
                        <Search
                            className='recommendation-search-bar'
                            placeholder="Search By Assessment"
                            onSearch={onSearch}
                            enterButton
                        />
                        {renderRecommendations()}
                    </div>
                )
            case 'Remediation':
                // return <div className="content">This is the Timeline content.</div>;
                return (
                    <div>
                        <Search
                            className='timeline-search-bar'
                            placeholder="Search By professions"
                            onSearch={onSearchProfession}
                            enterButton
                        />
                        <p className="viewAllTimelines" onClick={handleViewAllTimelinesOptionClick}>View All</p>
                        {renderTimelines()}
                    </div>
                )
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="tabs-container">
                <div className="tabs">
                    <div
                        className={`tab ${activeTab === 'Reports' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Reports')}
                    >
                        Reports
                    </div>
                    <div
                        className={`tab ${activeTab === 'Recommendations' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Recommendations')}
                    >
                        Recommendations
                        <span className="notification-badge">4</span>
                    </div>
                    <div
                        className={`tab ${activeTab === 'Remediation' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Remediation')}
                    >
                        Remediation
                        <span className="notification-badge">2</span>
                    </div>
                </div>
                <div className="button-container">
                    <button className="assessment-button">
                        Take new assessment
                    </button>
                </div>
            </div>
            {renderContent()}
        </div>
    );
}

export default TabsComponent;
