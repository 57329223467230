import React, { useState } from 'react';
import logo from "../../assets/company-logo.png";
import { Progress, Drawer } from 'antd';
import RemediationSidebarHeaderComponent from '../RemediationPage/remediationSidebarComponents/SidebarHeaderComponent';
import RemediationSidebarDescriptionComponent from '../RemediationPage/remediationSidebarComponents/RemediationSidebarDescriptionComponent';
import RemediationSidebarMilestoneComponent from '../RemediationPage/remediationSidebarComponents/RemediationSidebarMilestoneComponent';
import RemediationSidebarAttachmentComponent from '../RemediationPage/remediationSidebarComponents/RemediationSidebarAttachmentComponent';
import RemediationSidebarCommentsComponent from '../RemediationPage/remediationSidebarComponents/RemediationSidebarCommentsComponent';
import RemediationSidebarActionButtonsComponent from '../RemediationPage/remediationSidebarComponents/RemediationSidebarActionButtonsComponent';
import { useNavigate } from 'react-router-dom';

import '../../css/ReportCard.css';

const ReportCard = ({
    title,
    date,
    subtitle,
    price,
    assessment,
    profileImage,
    actionButtonTitle,
    startDate,
    endDate,
    progressCompleted,
    remediationButtonContainer = null,
    bookAMeetingButtonClick,
    remediationType,
    remediationReportData,
    professionalPersonalInformation
}) => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const showLoading = () => {
        setOpen(true);
        setLoading(true);

        // Simple loading mock. You should add cleanup logic in real world.
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const handleViewRemediationReport = (remediationReportType) => {
        // alert(`need to show the drawer component here after fetching some information for the ${remediationReportType} report`);
        showLoading();
    };

    const handleViewProfessionalDetailButtonClick = (professionalPersonalInformation) => {
        navigate('/professionalProfile', {
            state: {
                professionalPersonalInformation: professionalPersonalInformation,
            }
        });
    }

    return (
        <>
            <div className="reports-card-container">
                {profileImage && (
                    <div className="profile-section">
                        <img src={logo} alt="User Avatar" className="profile-avatar" />
                    </div>
                )}
                <div className="card-content">
                    <div className="card-section">
                        <div className="report-title">{title}</div>
                        {date && <div className="report-date">{date}</div>}
                        {subtitle && <div className="report-subtitle">{subtitle}</div>}
                        {price && <div className="report-price">{price}</div>}
                    </div>
                    <div
                        className={`card-section ${actionButtonTitle === "For Timeline" ? "card-section-timeline" : ""
                            }`}
                    >
                        {actionButtonTitle === "For Reports" && <div className="tag">{assessment}</div>}
                        {actionButtonTitle === "For Recommendations" && <div className="tag">{assessment}</div>}
                        {(actionButtonTitle === "For Timeline" || actionButtonTitle === "For Remediation Report Cards") && <div className="timelineDates" style={{ textAlign: "center" }}>{startDate} - {endDate}</div>}
                        {(actionButtonTitle === "For Timeline" || actionButtonTitle === "For Remediation Report Cards") &&
                            <Progress
                                percent={progressCompleted}
                                size="large"
                                strokeColor="#61D85E"
                                percentPosition={{
                                    align: 'start',
                                    type: 'outer',
                                }}
                            />
                        }
                    </div>
                    <div className="card-section buttons">
                        {/*{actionButtonTitle === "For Reports" && <button className="link-button">View Gaps</button>}*/}
                        {actionButtonTitle === "For Reports" && <button className="link-button">View Report</button>}
                        {actionButtonTitle === "For Recommendations" && <button class="book-a-meeting-recommendations" onClick={() => handleViewProfessionalDetailButtonClick(professionalPersonalInformation)}>Contact Professional</button>}
                        {(actionButtonTitle === "For Timeline" && remediationButtonContainer == null) && <button className="book-a-meeting-recommendations">View details</button>}
                        {remediationButtonContainer === "For Remediation" && (
                            <div>
                                <div className='remediationViewDetailsButton' onClick={() => handleViewRemediationReport(remediationType)}>View Details</div>
                            </div>
                        )}
                        {/* <button className="link-button">View Gaps</button>
                    <button className="link-button">View Report</button> */}
                    </div>
                </div>
            </div>

            <Drawer
                closable={false}
                destroyOnClose
                placement="right"
                width={700}
                open={open}
                loading={loading}
                onClose={() => setOpen(false)}
            >
                <RemediationSidebarHeaderComponent remediationReportData={remediationReportData}/>
                <RemediationSidebarDescriptionComponent remediationReportData={remediationReportData}/>
                <RemediationSidebarMilestoneComponent remediationReportData={remediationReportData}/>
                <RemediationSidebarAttachmentComponent remediationReportData={remediationReportData}/>
                <RemediationSidebarCommentsComponent remediationReportData={remediationReportData}/>
                <RemediationSidebarActionButtonsComponent remediationType={remediationType} remediationReportData={remediationReportData}/>
            </Drawer>

        </>
    );
};
export default ReportCard;
