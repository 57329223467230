import React from 'react';
import noDataIcon from "../../assets/noDataAvailable.png"
import '../../css/NoMailsHeaderComponent.css';

const NoDataViewComponent = ({title}) => {
    return (
        <div className="no-mails-header-container">
            <img
                src={noDataIcon}
                alt="No Mails"
                className="no-data-image"
            />
            <h1 className="header-title">{title}</h1>
            {/* <p className="header-subtitle">
                Reach out to professionals recommended for you to start your  investment readiness journey
            </p> */}
            {/* <button className="header-button">View Recommendations</button> */}
        </div>
    );
};

export default NoDataViewComponent;
