// import Dashboard from "./pages/Dashboard";
// import AssessmentForm from "./pages/AssessmentForm";
import React from "react";
import SignUpPageComponent from "./pages/SignUpPage";

function App() {
  return (
    <div className="App">
      {/* <Dashboard /> */}
      {/* <AssessmentForm /> */}
      <SignUpPageComponent />
    </div>
  );
}

export default App;
