import React from 'react';
import { Card, Typography, Tag } from 'antd';
import '../../css/RecommendedProfessaionalProfileSectionTwo.css'; // Assuming CSS is in this file

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const RecommendedProfessaionalProfileSectionTwo = ({professionalPersonalInformation}) => {
    return (
        <Card className="recommened-professional-profile-card" style={{ marginTop: "20px" }}>
            <div className="recommened-professional-expertise-section">
                <Title style={{ "fontSize": "24px", "fontWeight": "700", "marginBottom": "20px" }}>Expertise</Title>
                <div className="recommended-professional-tags-container">
                {professionalPersonalInformation.profile.skills.map((skill, index) => (
                    <Tag className='recommended-professional-tag' key={skill.id}>{skill.name}</Tag>
                ))}
                </div>
            </div>

        </Card>
    );
};

export default RecommendedProfessaionalProfileSectionTwo;
