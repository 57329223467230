import React from "react";
import "../../../css/RemediationSidebarMilestoneComponent.css";

const RemediationSidebarMilestoneComponent = ({ remediationReportData }) => {
    return (
        <>
            {remediationReportData.milestones.map((milestone, index) => (
                <div key={milestone.id} className="remediationSidebarMilestoneSection">
                    <div className="remediationSidebarMilestoneTitle">{`Milestone ${index + 1}: ${milestone.name}`}</div>
                    <div className="remediationSidebarMilestoneContent">
                        {milestone.tasks.map((task, taskIndex) => (
                            <div key={task.id}>
                                <div className="milestone-key-name">{`Task ${taskIndex + 1}:`}</div>
                                <div className={`milestone-key-value ${task.status === "completed" ? "text-strike-through-styling" : ""}`}>
                                    {task.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>

    )
};

export default RemediationSidebarMilestoneComponent;