const PasswordValidationComponent = (userEnteredPassword) => {
    if (userEnteredPassword.length < 8) {
        return [false, 'Password must be at least 8 characters long.'];
    }
    if (!/[a-z]/.test(userEnteredPassword)) {
        return [false, 'Password must contain at least one lowercase letter.'];
    }
    if (!/[A-Z]/.test(userEnteredPassword)) {
        return [false, 'Password must contain at least one uppercase letter.'];
    }
    if (!/[0-9]/.test(userEnteredPassword)) {
        return [false, 'Password must contain at least one digit.'];
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(userEnteredPassword)) {
        return [false, 'Password must contain at least one special character.'];
    }

    return [true, null];
};

export default PasswordValidationComponent;