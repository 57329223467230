import React from 'react';
import { Card, Typography, Input, Button } from 'antd';
import '../../css/RecommendedProfessaionalProfileSectionThree.css'; // Assuming CSS is in this file

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const RecommendedProfessaionalProfileSectionThree = () => {
    return (
        <Card className="recommened-professional-profile-card" style={{ marginTop: "20px" }}>
            <div className="send-message-to-recommended-professional">
            <Title style={{ "fontSize": "24px", "fontWeight": "700", "marginBottom": "20px" }}>Drop a message.</Title>
            <Input.TextArea className='input-text-area-to-send-message-to-recommended-professional' rows={4} placeholder="Enter your message here" />
            <Button type="primary" className="send-message-to-recommended-professional-btn">Send Message</Button>
            </div>
        </Card>
    );
};

export default RecommendedProfessaionalProfileSectionThree;
