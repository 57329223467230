import React, {useState, useEffect} from 'react';
import HeaderComponent from './HeaderComponent';
import CardContainer from './CardContainer';
import "../css/main_screen.css";

let cardsInfo = [
    { title: "Take an assessment", cardActionButtonTitle: "Start", isButtonEnabled: true, navigationPath: "/assessmentForm" },
    { title: "View reports & gaps", cardActionButtonTitle: "Start", isButtonEnabled: false },
    { title: "Connect with professionals", cardActionButtonTitle: "Start", isButtonEnabled: false },
    { title: "Showcase your business to investors", cardActionButtonTitle: "Start", isButtonEnabled: false }
];

const headerData = {
    title: "Welcome to Anvil List, your trusted partner on the path to investment readiness.",
    subTitle: "For the best experience, we recommend taking our assessment and setting up your profile. This will help us identify the gaps in your business and work towards closing them."
};

const CardComponent = ({ user_details={} }) => {
    const [cards, setCards] = useState(cardsInfo); // Initialize state

    useEffect(() => {
        const assessmentFormStatus_key_for_user = user_details.logged_in_user_id + "_assessment_form_status";
        const assessment_form_status_value_from_local_storage = localStorage.getItem(assessmentFormStatus_key_for_user);
        if (assessment_form_status_value_from_local_storage === "saved") {
            const updatedCards = [...cards];
            updatedCards[0].cardActionButtonTitle = "Continue";
            setCards(updatedCards); // Immutable update
        }
        else if (assessment_form_status_value_from_local_storage === "completed") {
            const updatedCards = [...cards];
            // updatedCards[0].cardActionButtonTitle = "Completed";
            
            // we are changing the functionality in this place
            // even though the user has once submitted the assessment form - he can be able to take it once again
            // therefore instead of keeping the card button for in this case as Completed and disabling it
            // we will keep it as Start and make it accessible to the user so that he can take the assessment again
            updatedCards[0].cardActionButtonTitle = "Start";
            setCards(updatedCards); // Immutable update
        } else {
            const updatedCards = [...cards];
            updatedCards[0].cardActionButtonTitle = "Start";
            setCards(updatedCards); // Immutable update
        }
    }, []);

    return (
        <div className="container">
            <HeaderComponent headerData={headerData} />
            <CardContainer cardsData={cards} user_details={user_details}/>
        </div>
    );
}

export default CardComponent;
