import React from 'react';
import CardDesign from './CardDesign';
import '../css/card_container.css';

const CardContainer = ({ cardsData = {}, user_details={} }) => {
    return (
        <div className="card-container">
            {cardsData.map((card, index) => (
                <CardDesign
                    key={index}
                    title={card.title}
                    cardActionButtonTitle={card.cardActionButtonTitle}
                    isButtonEnabled={card.isButtonEnabled}
                    startButtonNavigationPath={card.navigationPath}
                    user_details={user_details}
                />
            ))}
        </div>
    );
}

export default CardContainer;
