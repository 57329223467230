import React from 'react';
import { ConfigProvider, Steps } from 'antd';

const items = [
    { title: 'Enter your email address' },
    { title: 'Provide basic info' },
    { title: 'Create your password' },
];

const Progressstep = ({ current, onStepsChange = {} }) => {

    return (
        <div className="parent-container">
            <div className='progress-steps'>
                <ConfigProvider
                    theme={{
                        components: {
                            Steps: {
                                colorPrimary: '#F2C41E',
                                colorBorderBg: '#ffffff',
                            },
                        },
                    }}
                >
                    <Steps size="small" current={current} labelPlacement="vertical" items={items} onChange={onStepsChange} />
                </ConfigProvider>
            </div>
        </div>
    );
}

export default Progressstep;