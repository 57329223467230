import React from 'react';
import RemediationTabsComponent from './RemediationTabsComponent';
import "../../css/main_screen_content_for_main_dashboard.css";

const RemediationMainScreeContent = ({ user_details = {} }) => {
    return (
        <div className="container">
            <div className='tabs-component-styling'>
                <RemediationTabsComponent user_details={user_details}/>
            </div>
        </div>
    );
}

export default RemediationMainScreeContent;
