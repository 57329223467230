import React, {useState} from 'react';
import QuestionComponent from './QuestionComponent';
import RadioGroupComponent from './inputAnswerComponents/RadioGroupComponent';
import CheckboxCompoment from "./inputAnswerComponents/CheckboxComponent";
import DropdownComponent from './inputAnswerComponents/DropdownComponent';
import "../css/questionAndAnswerContainer.css";

const QuestionAndAnswerForAssessmentForm = ({ uniqueKey, questionToBeDisplayedQuestion , onValueChange = {}, savedQuestionData=null }) => {

    // console.log("the questio to be displayed is");
    // console.log(questionToBeDisplayedQuestion);

    const inputType = questionToBeDisplayedQuestion.inputType;

    const previousSavedQuestionData = savedQuestionData ? savedQuestionData[uniqueKey] : null;
    const previousSavedQuestionAnswerValue = previousSavedQuestionData ? previousSavedQuestionData[uniqueKey] : null;

    const initialStateForDropdown = previousSavedQuestionAnswerValue ? previousSavedQuestionAnswerValue : "";
    const intialStateForRadioButtonGroup = previousSavedQuestionAnswerValue ? previousSavedQuestionAnswerValue : "";
    const initialStateForCheckboxGroup = previousSavedQuestionAnswerValue ? previousSavedQuestionAnswerValue : [];

    const [valueFromAnswerComponent, setValueFromAnswerComponent] = useState(null);
    // This function is called by the child component
    const handleValueChangeFromAnswerComponent = (value) => {
        setValueFromAnswerComponent(value);
        console.log("Received from answer component:", value);
        onValueChange(value);
    };

    return (
        <div className="questionAndAnswerContainer">
            <QuestionComponent question={questionToBeDisplayedQuestion.question} />
            {inputType === 'radioboxes' && <RadioGroupComponent key={uniqueKey} radioBoxOptions={questionToBeDisplayedQuestion.options} onValueChange={handleValueChangeFromAnswerComponent} initialState={intialStateForRadioButtonGroup}/>}
            {inputType === 'checkboxes' && <CheckboxCompoment key={uniqueKey} checkBoxOptions={questionToBeDisplayedQuestion.options} onValueChange={handleValueChangeFromAnswerComponent} initialState={initialStateForCheckboxGroup}/>}
            {inputType === 'dropdown' && <DropdownComponent key={uniqueKey} dropdownOptions={questionToBeDisplayedQuestion.options} keyword={questionToBeDisplayedQuestion.dropdownKeyword} onValueChange={handleValueChangeFromAnswerComponent} initialState={initialStateForDropdown} />}
        </div>
    );
}

export default QuestionAndAnswerForAssessmentForm;
