import React from 'react';
import { Button } from 'antd';
import "../css/common_learn_more_button.css";

const CommonLearnButton = () => {
    return (
        <Button className="learnMoreButton">Learn More</Button>
    );
}

export default CommonLearnButton;
