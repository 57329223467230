import React from 'react';
import { Typography } from 'antd';
import '../css/questionComponent.css';

const { Title } = Typography;

const QuestionComponent = ({ question = "" }) => {
    return (
        <Title className='assessmentQuestion'>{question}</Title>
    );
}

export default QuestionComponent;