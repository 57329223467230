import React from "react";
import { Button } from "antd";
import "../../../css/RemediationSidebarActionButtonsComponent.css";

const RemediationSidebarActionButtonsComponent = ({remediationType, remediationReportData}) => {

    const pendingTimelineReportActionButtons = () => {
        return (
            <button className="acceptByButton" block>Accept by 29-08-2024</button>
        );
    };

    const acceptedTimelineReportActionButtons = () => {
        return (
            <div className="accepted-timeline-action-buttons-container">
                <button className="approveByButton" block>Approve</button>
                <button className="lay-a-complain-button" block>Lay a complain</button>
            </div>

        );
    };

    return (
        <div className="remediationSidebarActionButtonsSection">
            {remediationType === "pending" && pendingTimelineReportActionButtons()}
            {remediationType === "accepted" && acceptedTimelineReportActionButtons()}
        </div>
    )
};

export default RemediationSidebarActionButtonsComponent;