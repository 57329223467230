export const formatToLocalDate = (isoDateString) => {
    // Parse the ISO date string
    const date = new Date(isoDateString);

    // Extract day, month, and year from the date
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Format the date in the desired output format
    const formattedDate = `${day < 10 ? '0' + day : day}-${monthNames[monthIndex]}-${year}`;

    return formattedDate;
}

export const formatTimeStringToDateForTimeline = (isoString) => {
    // Create a new Date object from the ISO string
    const date = new Date(isoString);

    // Define arrays for month and day suffixes
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const daySuffixes = ["th", "st", "nd", "rd"];

    // Get day of the month
    const day = date.getUTCDate();

    // Determine the correct suffix for the day
    const suffix = (day % 10 === 1 && day !== 11) ? "st" :
        (day % 10 === 2 && day !== 12) ? "nd" :
            (day % 10 === 3 && day !== 13) ? "rd" : "th";

    // Get the month name
    const month = months[date.getUTCMonth()];

    // Format the date as "26th Jun"
    return `${day}${suffix} ${month}`;
}

export const formatTimelineEndDate = (dateString) => {
    const date = new Date(dateString);

    // Define arrays for month names and day suffixes
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get day of the month
    const day = date.getUTCDate();

    // Determine the correct suffix for the day
    const suffix = (day % 10 === 1 && day !== 11) ? "st" :
        (day % 10 === 2 && day !== 12) ? "nd" :
            (day % 10 === 3 && day !== 13) ? "rd" : "th";

    // Get the month name
    const month = months[date.getUTCMonth()];

    // Format the date as "26th Jun"
    return `${day}${suffix} ${month}`;
}

export const getFileAttachedDateTime = (inputDate) => {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Get hours, minutes, day, month, and year
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getUTCFullYear();

    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If 0 (midnight), make it 12

    // Pad minutes with leading zero if necessary
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Return the formatted string
    return `${hours}:${formattedMinutes} ${ampm}, ${day} ${month} ${year}`;
}

export const getCommentCreatedDate = (isoDateString) => {
    // Parse the ISO date string
    const date = new Date(isoDateString);

    // Extract day, month, and year from the date
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Determine the correct suffix for the day
    const suffix = (day % 10 === 1 && day !== 11) ? "st" :
        (day % 10 === 2 && day !== 12) ? "nd" :
            (day % 10 === 3 && day !== 13) ? "rd" : "th";

    // Format the date in the desired output format
    const formattedDate = `${day < 10 ? '0' + day : day}${suffix}-${monthNames[monthIndex]}-${year}`;

    return formattedDate;
}