import React from "react";
import avatarLogo from '../../assets/avatar.png';
import moreIcon from '../../assets/more.png';
import editIcon from '../../assets/edit.png';
import videoIcon from '../../assets/video.png';

import "../../css/userInfoComponent.css";

const UserInfoComponent = () => {

    return (
        <div className="userInfoSection">
            <div className="user">
                <img src={avatarLogo} alt="" />
                <h2>John Doe</h2>
            </div>
            <div className="icons">
                <img src={moreIcon} alt="" />
                <img src={editIcon} alt="" />
                <img src={videoIcon} alt="" />
            </div>
        </div>
    );

};

export default UserInfoComponent;