import React, { useState, useEffect, useCallback } from "react";
import SignUpPageHeaderComponent from "../components/signUpPageComponents/SignUpPageHeaderComponent";
import AccountCreationComponent from "../components/signUpPageComponents/AccountCreationComponent";
import SignupFormContainerComponent from "../components/signUpPageComponents/SignUpFormContainerComponent";
import EmailValidation from "../formValidations/emailValidation";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import "../css/signUpPage.css";

const ForgotPasswordPage = () => {
    const [apiBodyForForgotPassword, setApiBodyForForgotPassword] = useState({
        email: "kofi@email.com"
    });

    let defaultErrorMessage = "Please fill in all the fields before you continue to the next step";
    const [formFieldErrorMessage, setFormFieldErrorMessage] = useState(defaultErrorMessage);
    const [errorVisible, setErrorVisible] = useState(false);
    const [shouldProceed, setShouldProceed] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({}); // state for field Errors
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const loginComponentHeaderData = {
        "title": "Forgot Password",
        "subtitle": "Do not remember your password?",
        "coming_from": "forgotPassword"
    }

    let itemsToShow = [
        { label: "What is your email?", rules: [{ required: true, message: 'Please enter your email' }] }
    ];

    const buttonText = "Get one time password";
    const comingFrom = "forgotPassword";
    const emailKey = "What is your email?";

    const openUserNotificationBoxwithType = (type, notificationParameters, title) => {
        console.log("notificationParameters = ", notificationParameters.message);
        api[type]({
            message: title,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true
            // duration: 0,
        });
    };

    const validateEmptyFields = (values) => {
        // new variable for maintaining all the error messages
        let errors = {};
        let isThereAFormError = true;

        const userEmail = values[emailKey];
        if (userEmail === null || userEmail === undefined || userEmail.length === 0) {
            errors[emailKey] = 'Please enter your email address';
            isThereAFormError = false;
            // return false;
        }

        // checking the pattern for email validation
        if (!EmailValidation(null, userEmail)) {
            setFormFieldErrorMessage("Please enter the correct email address");
            errors[emailKey] = 'Please enter the correct email address';
            isThereAFormError = false;
            // return false;
        }
        setFormFieldErrorMessage(defaultErrorMessage);
        setFieldErrors(errors);
        return isThereAFormError;
        // return true;
    };

    const handleFormFinish = (values) => {

        console.log('Form values:', values);
        // validateEmptyFields
        if (!validateEmptyFields(values)) {
            // setErrorVisible(true);
            return;
        }
        setApiBodyForForgotPassword(prevState => ({
            ...prevState,
            email: values[emailKey]
        }));

        setShouldProceed(true);
    };

    const apiCallToResetPassword = useCallback(() => {
        setIsLoading(true);
        fetch('https://api.anvillist.com/api/v1/password/request-otp', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiBodyForForgotPassword)
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    // Handle error here
                    console.error('Request OTP failed:', errorData);
                    openUserNotificationBoxwithType("error", errorData, "Failed to request OTP");
                    throw new Error('Failed to request OTP');
                }
                return response.json();
            })
            .then(data => {
                console.log('OTP request successful:', data);
                openUserNotificationBoxwithType("success", data, "Successfully requested OTP");
                navigate('/resetPassword', {
                    state: {
                        userEnteredEmail: apiBodyForForgotPassword["email"],
                    }
                });
                // Handle success here (e.g., display a success message, redirect, etc.)
            })
            .catch(error => {
                console.error('An error occurred while requesting OTP:', error);
                // Handle general network or other errors here
            })
            .finally(() => {
                setIsLoading(false); // Stop loading regardless of the result
            });
    }, [apiBodyForForgotPassword, navigate]); // Include dependencies that could change

    useEffect(() => {
        if (shouldProceed) {
            console.log("apiBodyForForgotPassword", apiBodyForForgotPassword);
            apiCallToResetPassword();
            // setErrorVisible(false);
            setShouldProceed(false); // Reset the flag
        }
    }, [shouldProceed, apiBodyForForgotPassword, apiCallToResetPassword]); // Run when shouldProceed or currentStep changes


    return (
        <div className="signUpPageContainer">
            {contextHolder}
            <SignUpPageHeaderComponent />
            <AccountCreationComponent componentHeaderData={loginComponentHeaderData} />
            {errorVisible && (<div className='errorContainer'>{formFieldErrorMessage}</div>)}
            {!errorVisible && (<div style={{ marginTop: "40px" }}></div>)}
            <SignupFormContainerComponent
                formItems={itemsToShow}
                onFinish={handleFormFinish}
                buttonText={buttonText}
                comingFrom={comingFrom}
                fieldErrors={fieldErrors}
                isLoading={isLoading}
            />
            {/* <Link to="/signUp" onClick={(e) => e.stopPropagation()}>Forgot Password?</Link> */}
        </div>
    );

};

export default ForgotPasswordPage;