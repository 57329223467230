import React, { useState, useEffect, useCallback } from "react";
import SignUpPageHeaderComponent from "../components/signUpPageComponents/SignUpPageHeaderComponent";
import AccountCreationComponent from "../components/signUpPageComponents/AccountCreationComponent";
import SignupFormContainerComponent from "../components/signUpPageComponents/SignUpFormContainerComponent";
import EmailValidation from "../formValidations/emailValidation";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import "../css/signUpPage.css";

const LoginPageComponent = () => {
    const [apiBodyForLogin, setApiBodyForLogin] = useState({
        email: "kofi@email.com",
        password: "password"
    });

    let defaultErrorMessage = "Please fill in all the fields before you continue to the next step";
    const [formFieldErrorMessage, setFormFieldErrorMessage] = useState(defaultErrorMessage);
    const [errorVisible, setErrorVisible] = useState(false);
    const [shouldProceed, setShouldProceed] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({}); // state for field Errors
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const loginComponentHeaderData = {
        "title": "Welcome Back",
        "subtitle": "Do not have an account? ",
        "coming_from": "login"
    }

    let itemsToShow = [
        { label: "What is your email?", rules: [{ required: true, message: 'Please enter your email' }] },
        { label: "Password", rules: [{ required: true, message: 'Please confirm your password' }] },
    ];

    const buttonText = "Login";
    const comingFrom = "loginComponent";
    const emailKey = "What is your email?";
    const passwordKey = "Password";

    const openErrorNotification = (notificationParameters) => {
        console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Login Unsuccessfull',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true
        });
    };

    const validateEmptyFields = (values) => {
        // new variable for maintaining all the error messages
        let errors = {};
        let isThereAFormError = true;

        const userEmail = values[emailKey];
        if (userEmail === null || userEmail === undefined || userEmail.length === 0) {
            errors[emailKey] = 'Please enter your email address';
            isThereAFormError = false;
            //return false;
        }

        // checking the pattern for email validation
        if (!EmailValidation(null, userEmail)) {
            setFormFieldErrorMessage("Please enter the correct email address");
            errors[emailKey] = 'Please enter the correct email address';
            isThereAFormError = false;
            //return false;
        }
        // updateApiBodyForLogin("email", userEmail);
        setFormFieldErrorMessage(defaultErrorMessage);

        const userPassword = values[passwordKey];
        if (userPassword === null || userPassword === undefined || userPassword.length === 0) {
            setFormFieldErrorMessage("Please enter the password to Login");
            errors[passwordKey] = 'Please enter the password to Login';
            isThereAFormError = false;
            //return false;
        }
        // updateApiBodyForLogin("password", userPassword);
        setFormFieldErrorMessage(defaultErrorMessage);

        setFieldErrors(errors);
        return isThereAFormError;
        // return true;
    };

    const handleFormFinish = (values) => {
        console.log('Form values:', values);
        // validateEmptyFields
        if (!validateEmptyFields(values)) {
            // setErrorVisible(true);
            return;
        }

        setApiBodyForLogin(prevState => ({
            ...prevState,
            email: values[emailKey],
            password: values[passwordKey],
        }));

        setShouldProceed(true);
    };

    const loginUserIntoTheApplication = useCallback(() => {
        setIsLoading(true);
        fetch('https://api.anvillist.com/api/v1/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiBodyForLogin)
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    // Handle error here
                    console.error('Login failed:', errorData);
                    openErrorNotification(errorData);
                    throw new Error('Failed to login');
                }
                return response.json();
            })
            .then(data => {
                console.log('Login successful:', data);
                localStorage.setItem("anvillist_user_access_token", data.access_token);
                console.log("login successful response data - ", data);
                console.log("user id = ", data.data.id);
                sessionStorage.setItem("loggedInUserID", data.data.id);
                const user_details = {
                    "logged_in_user_id": data.data.id,
                    "user_access_token": data.access_token,
                    "user_name": data.data.name,
                }
                sessionStorage.setItem("user_details", JSON.stringify(user_details));
                const assessmentFormStatus_key_for_user = user_details.logged_in_user_id + "_assessment_form_status";
                const assessment_form_status_value_from_local_storage = localStorage.getItem(assessmentFormStatus_key_for_user);
                if (assessment_form_status_value_from_local_storage === "completed") {
                    navigate("/mainDashboard");
                } else {
                    navigate("/dashboard");
                }
                
                // Handle success here (e.g., display a success message, redirect, etc.)
            })
            .catch(error => {
                console.error('An error occurred while logging in:', error);
                // Handle general network or other errors here
            })
            .finally(() => {
                setIsLoading(false); // Stop loading regardless of the result
            });
    }, [apiBodyForLogin, navigate]); // Include dependencies that could change

    useEffect(() => {
        if (shouldProceed) {
            console.log("apiBodyForLogin", apiBodyForLogin);
            loginUserIntoTheApplication();
            // setErrorVisible(false);
            setShouldProceed(false); // Reset the flag
        }
    }, [shouldProceed, apiBodyForLogin, loginUserIntoTheApplication]); // Run when shouldProceed or currentStep changes


    return (
        <div className="signUpPageContainer">
            {contextHolder}
            <SignUpPageHeaderComponent />
            <AccountCreationComponent componentHeaderData={loginComponentHeaderData} />
            {errorVisible && (<div className='errorContainer'>{formFieldErrorMessage}</div>)}
            {!errorVisible && (<div style={{ marginTop: "40px" }}></div>)}
            <SignupFormContainerComponent
                formItems={itemsToShow}
                onFinish={handleFormFinish}
                buttonText={buttonText}
                comingFrom={comingFrom}
                fieldErrors={fieldErrors}
                isLoading={isLoading}
            />
            {/* <Link to="/signUp" onClick={(e) => e.stopPropagation()}>Forgot Password?</Link> */}
        </div>
    );
};

export default LoginPageComponent;