import React from 'react';
import noMailsIcon from "../assets/noMailsAvailableIcon.png"
import '../css/NoMailsHeaderComponent.css';

const NoMailsHeaderComponent = () => {
    return (
        <div className="no-mails-header-container">
            <img
                src={noMailsIcon}
                alt="No Mails"
                className="header-image"
            />
            <h1 className="header-title">No Message</h1>
            <p className="header-subtitle">Reach out to professionals recommended for you
                to start your  investment readiness journey</p>
            <button className="header-button">View Recommendations</button>
        </div>
    );
};

export default NoMailsHeaderComponent;
