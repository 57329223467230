import React from "react";
import companyLogo from "../../assets/company-logo.png";
import { Link } from "react-router-dom";

const SignUpPageHeaderComponent = () => {
    return (
        <header className="signUpPageHeader">
            <div className="logo">
                <img src={companyLogo} alt="Anvil Logo" />
            </div>
            <nav className="nav">
                {/* <ul>
                    <li><a href="">Sign up</a></li>
                    <li>|</li>
                    <li><a href="">Login</a></li>
                </ul> */}
                <ul>
                    <li><Link to="/signUp">Sign Up</Link></li>
                    <li>|</li>
                    <li><Link to="/login">Login</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default SignUpPageHeaderComponent