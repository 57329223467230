import React from "react";
import fileAttachedIcon from "../../../assets/fileAttachedIcon.png";
import fileViewIcon from "../../../assets/viewIcon.png";
import fileDownloadIcon from "../../../assets/downloadIcon.png";
import { getFileAttachedDateTime } from "../../FormattedDate";

import "../../../css/RemediationSidebarAttachmentComponent.css";

const RemediationSidebarAttachmentComponent = ({ remediationReportData }) => {
    const attachmentsData = remediationReportData.attachments.length !== 0;
    return (
        <div className="remediationSidebarAttachmentSection">
            {attachmentsData &&
                <>
                    <div className="remediationSidebarAttachmentTitle">Attachments</div>
                    <div className="remediationSidebarAttachmentContent">
                        {remediationReportData.attachments.map((attachment, index) => (
                            <div className="file-attachement-row" key={index}>
                                <div class="attached-file-info">
                                    <img src={fileAttachedIcon} alt="File Attached Icon" class="attached-file-icon" width={41} height={41} />
                                    <div class="attached-file-text-details">
                                        <div class="attached-file-name">{attachment.name == null ? "No File Name" : attachment.name}</div>
                                        <div class="attached-file-date">{getFileAttachedDateTime(attachment.createdAt)}</div>
                                    </div>
                                </div>

                                <div class="attached-file-actions">
                                    <button class="attached-file-view-button">
                                        <img src={fileViewIcon} alt="View" width={27} height={27} />
                                        <span className="file-action-span-text">View</span>
                                    </button>
                                    <button class="attached-file-download-button">
                                        <img src={fileDownloadIcon} alt="Download" width={20} height={20} />
                                        <span className="file-action-span-text">Download</span>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div>
    )
};

export default RemediationSidebarAttachmentComponent;