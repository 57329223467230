import React from 'react';
import { Progress } from 'antd';
import '../css/assessmentFormProgressBar.css'; 

const AssessmentFormProgressBar = ({progress = 0}) => {
  return (
    <Progress className="progress-bar" percent={progress} showInfo={false} size={["default", 20]} strokeColor="#7ff875"/>
  );
};

export default AssessmentFormProgressBar;
