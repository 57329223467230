// import React from 'react';
// import { Menu } from 'antd';
// import { MailOutlined } from '@ant-design/icons';
import logo from "../assets/company-logo.png";
import { useNavigate } from "react-router-dom";
import "../css/main_navigation_bar.css";

// const { SubMenu } = Menu;

// const Navbar = () => {
//   const [current, setCurrent] = React.useState('start');

//   const handleClick = (e) => {
//     console.log('click ', e);
//     setCurrent(e.key);
//   };

//   return (
//     <Menu
//       onClick={handleClick}
//       selectedKeys={[current]}
//       mode="horizontal"
//       style={{
//         lineHeight: '64px',
//         borderBottom: 'none',
//         boxShadow: '0 2px 8px #f0f1f2',
//         padding: '0 20px',
//         display: 'flex',
//         alignItems: 'center',
//       }}
//     >
//       <Menu.Item disabled key="logo" style={{ marginRight: 'auto', display: 'flex', alignItems: 'center' }}>
//         <img
//           src={logo}
//           alt="Logo"
//           style={{ marginRight: 8, height: 40 }}
//         />
//         {/* <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Anvil</span> */}
//       </Menu.Item>
//       <Menu.Item key="start" style={{ fontSize: '1rem' }}>
//         Start
//       </Menu.Item>
//       <Menu.Item key="shared-space" style={{ fontSize: '1rem' }}>
//         Shared Space
//       </Menu.Item>
//       <Menu.Item key="templates" style={{ fontSize: '1rem' }}>
//         Templates
//       </Menu.Item>
//       <Menu.Item key="meeting-rooms" style={{ fontSize: '1rem' }}>
//         Meeting Rooms
//       </Menu.Item>
//       <Menu.Item key="business" style={{ fontSize: '1rem', marginLeft: 'auto' }}>
//         Business
//       </Menu.Item>
//       <Menu.Item disabled style={{ padding: '0 5px' }}>
//         <div style={{ height: '50px', width: '1px', backgroundColor: "black" }} />
//       </Menu.Item>
//       <Menu.Item key="user" style={{ fontSize: '1rem' }}>
//         Micheal Orji
//       </Menu.Item>
//     </Menu>
//   );
// };

// export default Navbar;

// import React, { useState } from 'react';
// import { Menu } from 'antd';

// const Navbar = () => {
//   const [current, setCurrent] = useState('start');

//   const handleClick = (e) => {
//     setCurrent(e.key);
//   };

//   const menuItems = [
//     {
//       label: (
//         <div className="logo-item">
//           <img src={logo} alt="Logo" className="logo-img" />
//         </div>
//       ),
//       key: 'logo',
//       disabled: true
//     },
//     {
//       label: 'Start',
//       key: 'start',
//       className: current === 'start' ? 'menu-item menu-item-selected center-menu-item' : 'center-menu-item'
//     },
//     {
//       label: 'Shared Space',
//       key: 'shared-space',
//       className: current === 'shared-space' ? 'menu-item menu-item-selected center-menu-item' : 'center-menu-item'
//     },
//     {
//       label: 'Templates',
//       key: 'templates',
//       className: current === 'templates' ? 'menu-item menu-item-selected center-menu-item' : 'center-menu-item'
//     },
//     {
//       label: 'Meeting Rooms',
//       key: 'meeting-rooms',
//       className: current === 'meeting-rooms' ? 'menu-item menu-item-selected center-menu-item' : 'center-menu-item'
//     },
//     {
//       label: 'Business',
//       key: 'business',
//       className: current === 'business' ? 'menu-item menu-item-selected' : 'menu-item',
//       style: { marginLeft: 'auto' }
//     },
//     {
//       label: <div className="divider-line" />,
//       key: 'divider',
//       disabled: true,
//       className: 'menu-divider'
//     },
//     {
//       label: 'Micheal Orji',
//       key: 'user',
//       className: current === 'user' ? 'menu-item menu-item-selected' : 'menu-item'
//     }
//   ];

//   return (
//     <Menu
//       onClick={handleClick}
//       selectedKeys={[current]}
//       mode="horizontal"
//       className="navbar-menu"
//       items={menuItems}
//     />
//   );
// };

// export default Navbar;


import React, { useState } from 'react';
import { Menu, Dropdown } from 'antd';

const Navbar = ({ isNavbarForDashboard = true, user_details = {} }) => {
    const [current, setCurrent] = useState('start');
    const navigate = useNavigate();

    const handleClick = (e) => {
        const currentKey = e.key;
        console.log(e);
        setCurrent(currentKey);
    };

    // This handles clicks from the dropdown menu
    const handleDropdownClick = ({ key }) => {
        console.log('Dropdown Menu Clicked:', key);
        if (key === 'logout') {
            // Handle logout logic here
            console.log('Logout clicked');
            const userAccessToken = localStorage.getItem("anvillist_user_access_token");
            logoutUser(userAccessToken);
        }
    };

    const logoutUser = (userAccessToken) => {
        fetch('https://api.anvillist.com/api/v1/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userAccessToken}`
            },
            body: JSON.stringify({})
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Logout failed:', errorData);
                    throw new Error('Failed to logout');
                }
                return response.json();
            })
            .then(data => {
                console.log('Logout successful:', data);
                localStorage.removeItem("anvillist_user_access_token");
                sessionStorage.removeItem("user_details");
                navigate("/login");
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
    };

    const logoItem = {
        label: (
            <div className="logo-item">
                <img
                    src={logo}
                    alt="Logo"
                    className="logo-img"
                />
            </div>
        ),
        key: 'logo',
        disabled: true
    };

    const centerMenuItems = [
        {
            label: 'Start',
            key: 'start',
            className: current === 'start' ? 'menu-item menu-item-selected' : 'menu-item',
        },

        {
            label: 'Dashboard',
            key: 'dashboard',
            className: current === 'dashboard' ? 'menu-item menu-item-selected' : 'menu-item',
        },

        {
            label: 'Remediation',
            key: 'remediation',
            className: current === 'remediation' ? 'menu-item menu-item-selected' : 'menu-item',
        },

        {
            label: 'Data Room',
            key: 'data room',
            className: current === 'data room' ? 'menu-item menu-item-selected' : 'menu-item',
        },

        {
            label: 'Meetings',
            key: 'meetings',
            className: current === 'shared-space' ? 'menu-item menu-item-selected' : 'menu-item',
        },
        {
            label: 'Show Case',
            key: 'show case',
            className: current === 'templates' ? 'menu-item menu-item-selected' : 'menu-item',
        }
    ];

    const rightMenuItems = [
        {
            label: <div className="userType">Business</div>,
            key: 'business',
            className: current === 'business' ? 'menu-item menu-item-selected' : 'menu-item',
            disabled: true,
        },
        {
            label: <div className="divider-line" />,
            key: 'divider',
            disabled: true,
            className: 'menu-divider'
        },
        {
            label: (
                <Dropdown overlay={
                    <Menu className="dropdown-menu" onClick={handleDropdownClick}>
                        <Menu.Item key="logout" className="dropdown-menu-item">Logout</Menu.Item>
                    </Menu>
                } placement="bottomLeft">
                    <span>{user_details.user_name}</span>
                </Dropdown>
            ),
            key: 'user',
            className: current === 'user' ? 'menu-item menu-item-selected' : 'menu-item',
        },
    ];

    const emptySetOfMenuItems = [];

    // return (
    //     <div className="navbar-menu">
    //         <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={[logoItem]} />
    //         {/* <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={leftMenuItems} style={{ marginLeft: 'auto' }} /> */}
    //         <div className="center-menu-container">
    //             {/* <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={centerMenuItems} /> */}
    //             <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="center-menu" items={centerMenuItems} />
    //         </div>
    //         <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={rightMenuItems} />
    //         {/* <div className="divider-line" style={{ margin: '0 16px' }} /> */}
    //     </div>
    // );

    return (
        <div className="navbar-menu">
            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={[logoItem]} />
            {/* <div className="center-menu-container">
                <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="center-menu" items={centerMenuItems} />
            </div> */}
            {/* <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={leftMenuItems} /> */}
            {/* <div className="right-menu-container">
                <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="right-menu-item" items={rightMenuItems} />
            </div> */}
            {isNavbarForDashboard ? (
                <>
                    <div className="center-menu-container">
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="center-menu" items={centerMenuItems} />
                    </div>
                    <div className="right-menu-container">
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="right-menu-item" items={rightMenuItems} />
                    </div>
                </>
            ) : (
                <>
                    <div className="center-menu-container">
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="center-menu" items={emptySetOfMenuItems} />
                    </div>
                    <div className="right-menu-container">
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="right-menu-item" items={emptySetOfMenuItems} />
                    </div>
                </>
            )}
        </div>
    );
};

export default Navbar;
