import React from "react";
import randomUserImage from "../../../assets/randomUserImage.jpeg";
import { getCommentCreatedDate } from "../../FormattedDate";
import "../../../css/RemediationSidebarCommentsComponent.css";

const RemediationSidebarCommentsComponent = ({ remediationReportData }) => {
    console.log("comments data coming in from the api is - ", remediationReportData);
    const commentsData = remediationReportData.comments ? remediationReportData.comments : [];
    return (
        <div className="remediationSidebarCommentsSection">
            <div className="remediationSidebarCommentsTitle">Comments</div>
            <div className="remediationSidebarCommentsContent">
                {commentsData.map((comment, index) => (
                    <div class="remediation-comment-container" key={comment.id}>
                        <div class="remediation-comment-avatar">
                            <img src={randomUserImage} alt="User Avatar" />
                        </div>
                        <div class="remediation-comment-content">
                            <div class="remediation-comment-user-details">
                                <span class="remediation-comment-user-name">{comment.user.name} . </span>
                                <span class="remediation-comment-comment-date">{getCommentCreatedDate(comment.createdAt)}</span>
                            </div>
                            <div class="remediation-comment-message">
                                {comment.comment}.
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="send-comment-input-box">
                <input type="text" placeholder="Add a commentt" />
            </div>
        </div>
    )
};

export default RemediationSidebarCommentsComponent;