import React, { useState, useEffect } from 'react';
import AssessmentFormHeader from './AssessmentFormHeader';
import QuestionAndAnswerForAssessmentForm from "../components/QuestionAndAnswerForAssessmentForm";
import ContinueButtonForAssessmentForm from './inputAnswerComponents/ContinueButtonForAssessmentForm';
import assessmentFormData from "../assessmentQuestions/list_of_questions_from_google_form.json";
import assessmentFormBackendKeys from "../assessmentQuestions/jsonBodyToSendToBackend.json";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import "../css/main_screen.css";

const assessmentFormDataQuestions = assessmentFormData;
const totalNumberOfSections = 8;
const totalNumberOfQuestions = assessmentFormDataQuestions.length;
// console.log("This is the list of all the questions to be displayed in the assessmentForm = ", totalNumberOfQuestions, totalNumberOfSections);
// console.log(assessmentFormData);
// console.log(assessmentFormDataQuestions[3]);

// to do things
// 1. Prev button functionality -- DONE
// 2. save & exit button functionality -- DONE
//  a. implement the localStorage functionality -- DONE
//  b. but then again make sure that you are not only inserting new values into the local storage -- DONE 
//      i. but are also able to update the old answers -- DONE

// to do things - part 2
// 1. sign up page integration
// 2. login page integration
// 3. validations for the sign up page fields
// 4. validations for the continue button on the assessment form
//      a. if the users tries to press on the continue button without selecting an answer

// to do things - part 3
// 1. handle refresh functionality on the page itself when doing the assessment form
//    a. need to make sure that the form starts from the same point as it got refreshed

// userid for testing purpose - fa81d42f-9d06-4cb1-bf8a-378097425cf8

const AssessmentFormMainScreen = ({ user_details = {} }) => {

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [userSelectedAnswers, setAnswers] = useState([]);
    const [valueFromQuestionAndAnswerComponent, setValueFromQuestionAndAnswerComponent] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [errorVisible, setErrorVisible] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    // // State Management for the Dropdown Component
    // const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        // Load the saved answers from localStorage when the component mounts
        const user_specific_key = user_details.logged_in_user_id;
        const savedAnswers = JSON.parse(localStorage.getItem(user_specific_key)) || [];
        console.log(`saved answers from the current user = ${user_specific_key} is `, savedAnswers);
        setAnswers(savedAnswers);

        // get current question index
        const checkpoint_question_index_number = user_details.logged_in_user_id + "_checkpoint_question_index_number";
        const question_number = localStorage.getItem(checkpoint_question_index_number);
        let final_question_number_to_be_rendered = question_number != undefined ? question_number : 0;
        console.log("question number = " + question_number + " final question number is = " + final_question_number_to_be_rendered);
        final_question_number_to_be_rendered = parseInt(final_question_number_to_be_rendered);

        // // set the saved progress on the page
        console.log("checking the totla number of questions coming up in the use effect hook = ", totalNumberOfQuestions);
        const value_1 = final_question_number_to_be_rendered;
        const saved_progress_value = (value_1 / parseInt(totalNumberOfQuestions));
        console.log("saved_progress_value = ", saved_progress_value);
        setProgress(saved_progress_value * 100);

        // setting the current question index to display that question on the page
        final_question_number_to_be_rendered = (final_question_number_to_be_rendered == (totalNumberOfQuestions - 1)) ? (final_question_number_to_be_rendered - 1) : final_question_number_to_be_rendered;
        setCurrentQuestionIndex(parseInt(final_question_number_to_be_rendered));

        setLoading(false); // Set loading to false once the data is loaded
    }, []);

    const addAnswer = () => {
        const newAnswer = {
            [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
        };
        console.log("newAnswer :- ", newAnswer);
        const updatedAnswers = [...userSelectedAnswers, newAnswer];
        console.log("updatedAnswers :- ", updatedAnswers);
        setAnswers(updatedAnswers);
        // console.log("userSelectedAnswers :- ", userSelectedAnswers);
        // changing the format for local storage to store the saved answers with respect to the user logged in
        // instead of using the generic key name like - userSelectedAnswers
        // i now need to use the user id as the local storage key
        // what this does is that it would create individual local storage objects for different logged users on the same laptop

        // Save updated answers to local storage
        // const user_specific_key = user_details.logged_in_user_id;
        // localStorage.setItem(user_specific_key, JSON.stringify(updatedAnswers));
    };

    const updateAnswer = () => {
        let updatedAnswers = [...userSelectedAnswers];
        if ((updatedAnswers[currentQuestionIndex] === null) || (updatedAnswers[currentQuestionIndex] === undefined)) {
            updatedAnswers[currentQuestionIndex] = {
                [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
            }
        } else {
            updatedAnswers[currentQuestionIndex][currentQuestionIndex] = valueFromQuestionAndAnswerComponent;
        }
        setAnswers(updatedAnswers);
        // changing the format for local storage to store the saved answers with respect to the user logged in
        // instead of using the generic key name like - userSelectedAnswers
        // i now need to use the user id as the local storage key
        // what this does is that it would create individual local storage objects for different logged users on the same laptop

        // Save updated answers to local storage
        // const user_specific_key = user_details.logged_in_user_id;
        // localStorage.setItem(user_specific_key, JSON.stringify(updatedAnswers));
    }

    const addOrUpdateAnswer = () => {
        if (currentQuestionIndex in userSelectedAnswers) {
            updateAnswer();
        } else {
            addAnswer();
        }
    };

    const isTherePreviousAnswer = () => {
        if (currentQuestionIndex in userSelectedAnswers) {
            const previousData = userSelectedAnswers[currentQuestionIndex];
            const previousAnswer = (previousData != null) ? previousData[currentQuestionIndex] : null;
            console.log("previous answer :- ", previousAnswer);
            if (previousAnswer != null) {
                return true;
            }
            return false;
        }
        return false;
    };

    const handleContinue = () => {
        console.log("valueFromQuestionAndAnswerComponent : ", valueFromQuestionAndAnswerComponent);

        if (valueFromQuestionAndAnswerComponent == null) {
            if ((isTherePreviousAnswer() === false)) {
                setErrorVisible(true);
                return;
            }
        }

        if ((valueFromQuestionAndAnswerComponent !== null)) {
            addOrUpdateAnswer();
        }
        console.log("in the continue handle function");
        if (currentQuestionIndex < assessmentFormDataQuestions.length - 1) {
            // handleStateResetForTheAnswerComponents();
            setProgress(((currentQuestionIndex + 1) / totalNumberOfQuestions) * 100);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setValueFromQuestionAndAnswerComponent(null);
            setErrorVisible(false);
        } else {
            // setting the progress to 100% after selecting the last question
            setProgress(((currentQuestionIndex + 1) / totalNumberOfSections) * 100);
            const copy_value = valueFromQuestionAndAnswerComponent;
            setValueFromQuestionAndAnswerComponent(null);
            setErrorVisible(false);
            makeJSONBodyForAPI(copy_value);
            submitAssessmentForm();
        }
        // Optional: Handle completion case if needed
        // alert("clicked on the continue button");
        // setValueFromQuestionAndAnswerComponent(null);
        // setErrorVisible(false);
    };

    // const handleStateResetForTheAnswerComponents = () => {
    //     const question_input_type = assessmentFormDataQuestions[currentQuestionIndex].inputType;
    //     if (question_input_type === 'dropdown') {
    //         handleStateResetForTheDropdownComponent();
    //     }
    // };

    // This function is called by the child component
    const handleValueChangeFromTheQuestionAndAnswerComponent = (value) => {
        setValueFromQuestionAndAnswerComponent(value);
        console.log("Received from QuestionAndAnswerComponent:", value);
    };

    // const handleStateResetForTheDropdownComponent = () => {
    //     setSelectedOption(""); // Reset the dropdown
    // };

    const handlePreviousButton = () => {
        if ((valueFromQuestionAndAnswerComponent !== null)) {
            addOrUpdateAnswer();
        }

        console.log("in the previous button handle function");
        if (currentQuestionIndex > 0) {
            // decreasing the progress
            setProgress(((currentQuestionIndex - 1) / totalNumberOfQuestions) * 100);
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        } else {
            alert("This is the first question!!");
        }
        setValueFromQuestionAndAnswerComponent(null);
        setErrorVisible(false);
    };

    const handleSaveAndExitButton = () => {
        let updatedAnswers = [...userSelectedAnswers];
        if ((valueFromQuestionAndAnswerComponent !== null)) {
            if (currentQuestionIndex in userSelectedAnswers) {
                if ((updatedAnswers[currentQuestionIndex] === null) || (updatedAnswers[currentQuestionIndex] === undefined)) {
                    updatedAnswers[currentQuestionIndex] = {
                        [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
                    }
                } else {
                    updatedAnswers[currentQuestionIndex][currentQuestionIndex] = valueFromQuestionAndAnswerComponent;
                }
                setAnswers(updatedAnswers);
            } else {
                const newAnswer = {
                    [currentQuestionIndex]: valueFromQuestionAndAnswerComponent
                };
                console.log("newAnswer :- ", newAnswer);
                updatedAnswers = [...userSelectedAnswers, newAnswer];
                console.log("updatedAnswers :- ", updatedAnswers);
                setAnswers(updatedAnswers);
            }
        }

        const user_specific_key = user_details.logged_in_user_id;
        console.log("saved answers in this session are = ", updatedAnswers);

        // setting the current saved answers in the state as the new checkpoint for saved answers
        localStorage.setItem(user_specific_key, JSON.stringify(updatedAnswers));

        // setting the local storage for whether the assessment is saved and being exited or is it being submitted completely
        const assessmentFormStatus = "saved";
        const assessmentFormStatus_key_for_user = user_details.logged_in_user_id + "_assessment_form_status";
        localStorage.setItem(assessmentFormStatus_key_for_user, assessmentFormStatus);

        // saving the current question index number as the save checkpoint
        const checkpoint_question_index_number = user_details.logged_in_user_id + "_checkpoint_question_index_number"
        localStorage.setItem(checkpoint_question_index_number, currentQuestionIndex);
        console.log("data set in local storage for the current question number = ", localStorage.getItem(checkpoint_question_index_number));

        navigate("/dashboard");
    };

    const makeJSONBodyForAPI = (copy_value) => {
        const backend_keys = Object.keys(assessmentFormBackendKeys);
        const backend_keys_length = backend_keys.length;
        console.log("userSelectedAnswers = ", userSelectedAnswers);
        for (var i = 0; i < (backend_keys_length - 1); i++) {
            const key = backend_keys[i];
            console.log("key = ", key);
            assessmentFormBackendKeys[key] = userSelectedAnswers[i][i];
        }
        assessmentFormBackendKeys[backend_keys[backend_keys_length - 1]] = copy_value;
        console.log("the json body for the backend api is ");
        console.log(assessmentFormBackendKeys);
    };

    const submitAssessmentForm = async () => {
        console.log(assessmentFormBackendKeys);
        await fetch(`https://api.anvillist.com/api/v1/user/${user_details.logged_in_user_id}/create-assessment`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user_details.user_access_token}`
            },
            body: JSON.stringify(assessmentFormBackendKeys)
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Failed to submit the assessment failed:', errorData);
                    openErrorNotification(errorData);
                    throw new Error('Failed to submit the assessment failed');
                }
                return response.json();
            })
            .then(data => {
                console.log('Successfully submitted the assessment:', data);
                const assessmentFormStatus = "completed";
                const assessmentFormStatus_key_for_user = user_details.logged_in_user_id + "_assessment_form_status";
                localStorage.setItem(assessmentFormStatus_key_for_user, assessmentFormStatus);

                // setting the question checkpoint back to 0 in order to make sure the functionality stays intact
                // this is because when the user submits one assessment he should be able to take new assessment
                // so for that I need to reset the local storage to the initial values in order to make it as a fresh start
                
                // therefore, I am starting off by setting the question number checkpoint for that user to be 0. 
                const checkpoint_question_index_number = user_details.logged_in_user_id + "_checkpoint_question_index_number"
                localStorage.setItem(checkpoint_question_index_number, parseInt(0));

                // now reset the local storage for that contains the saved answers to an mepty array
                const resetANswersToEmptyArray = [];
                const user_specific_key = user_details.logged_in_user_id;
                localStorage.setItem(user_specific_key, JSON.stringify(resetANswersToEmptyArray));

                navigate("/mainDashboard");
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
    };

    const openErrorNotification = (notificationParameters) => {
        console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to submit the assessment failed',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    if (loading) {
        return <div>Loading...</div>; // Or any loading indicator you prefer
    }

    return (
        <div className="container" style={{ paddingTop: "0px", top: "-1%" }}>
            {contextHolder}
            <AssessmentFormHeader
                progress={progress}
                sectionInformationToBeDisplayed={assessmentFormDataQuestions[currentQuestionIndex]}
                totalNumberOfSections={totalNumberOfSections}
                onPreviousButtonClick={handlePreviousButton}
                onSaveAndExitButtonClick={handleSaveAndExitButton}
            />
            <QuestionAndAnswerForAssessmentForm
                uniqueKey={currentQuestionIndex}
                questionToBeDisplayedQuestion={assessmentFormDataQuestions[currentQuestionIndex]}
                onValueChange={handleValueChangeFromTheQuestionAndAnswerComponent}
                savedQuestionData={userSelectedAnswers}
            />
            {errorVisible && (
                <div className='errorContainer'>Please select an option before clicking on the Continue button</div>
            )}
            <ContinueButtonForAssessmentForm onClick={handleContinue} />
        </div>
    );
}

export default AssessmentFormMainScreen;
