import React from "react";
import "../../../css/RemediationSidebarDescriptionComponent.css";

const RemediationSidebarDescriptionComponent = ({remediationReportData}) => {
    return (
        <div className="remediationSidebarDescriptionSection">
            <div className="remediationSidebarDescriptionTitle">Description</div>
            <div className="remediationSidebarDescriptionContent">{remediationReportData.description}</div>
        </div>
    )
};

export default RemediationSidebarDescriptionComponent;