import React, { useState, useEffect, useCallback } from "react";
import SignUpPageHeaderComponent from "../components/signUpPageComponents/SignUpPageHeaderComponent";
import AccountCreationComponent from "../components/signUpPageComponents/AccountCreationComponent";
import SignupFormContainerComponent from "../components/signUpPageComponents/SignUpFormContainerComponent";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PasswordValidationComponent from "../formValidations/passwordValidations";
import { Form, notification } from "antd";
import "../css/signUpPage.css";

const ResetPasswordPage = () => {
    const location = useLocation();
    const { userEnteredEmail } = location.state || {};
    const [apiBodyForResetPassword, setApiBodyForResetPassword] = useState({
        "email": userEnteredEmail,
        "otp": "1QW00e",
        "password": "password1"
    });

    let defaultErrorMessage = "Please fill in all the fields before you continue to the next step";
    const [formFieldErrorMessage, setFormFieldErrorMessage] = useState(defaultErrorMessage);
    const [errorVisible, setErrorVisible] = useState(false);
    const [shouldProceed, setShouldProceed] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({}); // state for field Errors
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();  // Use the Ant Design Form instance
    const navigate = useNavigate();

    const loginComponentHeaderData = {
        "title": "Forgot Password",
        "subtitle": "Do not remember your password?",
        "coming_from": "forgotPassword"
    }

    let itemsToShow = [
        {
            label: "Enter the one time password from your email",
            rules: [
                {
                    required: true,
                    message: 'Enter the one time password from your email.'
                }
            ]
        },
        {
            label: "Enter new password",
            rules: [
                {
                    required: true,
                    message: 'Enter new password'
                }
            ]
        },
        {
            label: "Confirm new password",
            rules: [
                {
                    required: true,
                    message: 'Confirm new password'
                }
            ]
        }
    ];

    const buttonText = "Reset Password";
    const comingFrom = "resetPassword";
    const otpKey = "Enter the one time password from your email";
    const userPasswordKey = "Enter new password";
    const userConfirmPasswordKey = "Confirm new password";

    const openErrorNotification = (notificationParameters) => {
        console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to reset Password',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    const validateEmptyFields = (values) => {
        // new variable for maintaining all the error messages
        let errors = {};
        let isThereAFormError = true;

        const userEnteredOTP = values[otpKey];
        if (userEnteredOTP === null || userEnteredOTP === undefined || userEnteredOTP.length === 0) {
            errors[otpKey] = 'Please enter the otp sent to you in the mail';
            isThereAFormError = false;
            // return false;
        }
        setFormFieldErrorMessage(defaultErrorMessage);

        const userEnteredPassword = values[userPasswordKey];
        if (userEnteredPassword === null || userEnteredPassword === undefined || userEnteredPassword.length === 0) {
            errors[userPasswordKey] = 'Please enter the new password';
            isThereAFormError = false;
            // return false;
        }
        const passwordValidationCheck = PasswordValidationComponent(userEnteredPassword);
        if (!passwordValidationCheck[0]) {
            setFormFieldErrorMessage(passwordValidationCheck[1]);
            errors[userPasswordKey] = passwordValidationCheck[1];
            isThereAFormError = false;
            // return false;
        }
        setFormFieldErrorMessage(defaultErrorMessage);

        const userEnteredConfirmPassword = values[userConfirmPasswordKey];
        if (userEnteredConfirmPassword === null || userEnteredConfirmPassword === undefined || userEnteredConfirmPassword.length === 0) {
            errors[userConfirmPasswordKey] = 'Please enter password again to confirm';
            isThereAFormError = false;
            // return false;
        }
        if (userEnteredConfirmPassword !== userEnteredPassword) {
            setFormFieldErrorMessage("Passwords do not Match");
            errors[userConfirmPasswordKey] = 'Passwords do not Match';
            isThereAFormError = false;
            // return false;
        }
        setFormFieldErrorMessage(defaultErrorMessage);
        setFieldErrors(errors);
        return isThereAFormError;
        // return true;
    };

    const handleFormFinish = (values) => {
        // alert("handling form finish for the reset password component");
        //navigate("/login");
        console.log("userEnteredEmail");
        console.log(userEnteredEmail);
        console.log("Form Values :- ", values);
        // validateEmptyFields
        if (!validateEmptyFields(values)) {
            // setErrorVisible(true);
            return;
        }

        setApiBodyForResetPassword(prevState => ({
            ...prevState,
            "otp": values[otpKey],
            "password": values[userPasswordKey]
        }));

        setShouldProceed(true);
    };

    const apiCallToResetPasswordForUser = useCallback(() => {
        setIsLoading(true);
        fetch('https://api.anvillist.com/api/v1/password/reset', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiBodyForResetPassword)
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    // Handle error here
                    console.error('Password Reset failed:', errorData);
                    openErrorNotification(errorData);
                    throw new Error('Failed to reset password');
                }
                return response.json();
            })
            .then(data => {
                console.log('Password Reset successful:', data);
                navigate("/login");
                // Handle success here (e.g., display a success message, redirect, etc.)
            })
            .catch(error => {
                console.error('An error occurred while reseting password:', error);
                // Handle general network or other errors here
            })
            .finally(() => {
                setIsLoading(false); // Stop loading regardless of the result
            });
    }, [apiBodyForResetPassword, navigate]); // Include dependencies that could change

    useEffect(() => {
        if (shouldProceed) {
            console.log("apiBodyForForgotPassword", apiBodyForResetPassword);
            apiCallToResetPasswordForUser();
            // setErrorVisible(false);
            setShouldProceed(false); // Reset the flag
        }
    }, [shouldProceed, apiBodyForResetPassword, apiCallToResetPasswordForUser]); // Run when shouldProceed or currentStep changes


    return (
        <div className="signUpPageContainer">
            {contextHolder}
            <SignUpPageHeaderComponent />
            <AccountCreationComponent componentHeaderData={loginComponentHeaderData} />
            {errorVisible && (<div className='errorContainer'>{formFieldErrorMessage}</div>)}
            {!errorVisible && (<div style={{ marginTop: "40px" }}></div>)}
            <SignupFormContainerComponent
                form={form}
                formItems={itemsToShow}
                onFinish={handleFormFinish}
                buttonText={buttonText}
                comingFrom={comingFrom}
                fieldErrors={fieldErrors}
                isLoading={isLoading}
            />
            {/* <Link to="/signUp" onClick={(e) => e.stopPropagation()}>Forgot Password?</Link> */}
        </div>
    );

};

export default ResetPasswordPage;