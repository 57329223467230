import React from "react";
import { Link } from "react-router-dom";
import companyLogo from "../../assets/company-logo.png";

const AccountCreationComponent = ({componentHeaderData = {}}) => {
    return (
        <div className="account-creation">
            <div className="account-logo">
                {/* <img src={companyLogo} alt="Anvil Logo" /> */}
                <h1 className="account-title">{componentHeaderData.title}</h1>
                <p className="account-subtitle">
                    {componentHeaderData.subtitle}
                    {componentHeaderData.coming_from === "login" && <Link to="/signUp">Sign Up.</Link>}
                    {componentHeaderData.coming_from === "signup" && <Link to="/login">Log in.</Link>}
                </p>
            </div>
        </div>
    );
}

export default AccountCreationComponent;