import React, { useState } from 'react';
import { Radio } from 'antd';
import "../../css/radioGroupComponent.css";

const RadioGroupComponent = ({ radioBoxOptions = {}, initialState = "", onValueChange = {} }) => {
    const [selectedOptionByTheUser, setValueSelectedInTheRadioButtonGroup] = useState(initialState);

    const handleRadioButtonChange = (event) => {
        const selectedValueFromRadioButtonGroup = event.target.value;
        console.log("selectedValueFromRadioButtonGroup :- ", selectedValueFromRadioButtonGroup);
        setValueSelectedInTheRadioButtonGroup(selectedValueFromRadioButtonGroup);
        onValueChange(selectedValueFromRadioButtonGroup);
    };

    return (
        <div className='radio-group-container'>
            <Radio.Group onChange={handleRadioButtonChange} value={selectedOptionByTheUser} className="radio-group">
                {radioBoxOptions.map(option => (
                    <Radio key={option.value} value={option.value}>
                        {option.label}
                    </Radio>
                ))}
            </Radio.Group>
        </div>

    );
}

export default RadioGroupComponent;
