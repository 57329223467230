import React from 'react';
import { Text, Card } from 'antd';
import "../css/card_design.css";
import CardButtonGroup from './CardButtonGroup';

const CardDesign = ({ title, cardActionButtonTitle = "Start", isButtonEnabled, startButtonNavigationPath = "", user_details={}}) => {
    return (
        <Card className="card">
            <div className="card-content">
                <span className="card-title">{title}</span>
                <CardButtonGroup
                    cardActionButtonTitle={cardActionButtonTitle}
                    isButtonEnabled={isButtonEnabled}
                    startButtonNavigationPath={startButtonNavigationPath}
                    user_details={user_details}
                />
            </div>
        </Card>
    );
}

export default CardDesign;
