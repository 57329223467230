import React from 'react';
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import "../css/common_yellow_color_background_button.css";

const CommonYellowBackgroundColorButton = ({ cardActionButtonTitle = "Start", isButtonEnabled = true, startButtonNavigationPath = "", user_details = {} }) => {

    const navigate = useNavigate();

    const onClickCardStartButton = () => {
        if (startButtonNavigationPath !== "") {
            navigate(startButtonNavigationPath, {
                state: {
                    user_details: user_details
                }
            });
        }
    }

    return (
        <Button
            className="commonYellowBackGroundButton"
            disabled={cardActionButtonTitle === "Completed" || !isButtonEnabled}
            onClick={onClickCardStartButton}>
            {cardActionButtonTitle}
        </Button>
    );
};

export default CommonYellowBackgroundColorButton;
