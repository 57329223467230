import React from 'react';
import '../../css/welcomeMessage.css';

const WelcomeMessage = ({ title, subtitle }) => {
  return (
    <div className="welcome-message-container">
      <h1 className="welcome-title">{title}</h1>
      <p className="welcome-subtitle">{subtitle}</p>
    </div>
  );
}

export default WelcomeMessage;
