import React from 'react';
import { Card, Avatar, Typography } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import userProfilePhoto from "../../assets/randomUserImage.jpeg";
import '../../css/RecommendedProfessaionalProfileSectionOne.css'; // Assuming CSS is in this file

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const RecommendedProfessaionalProfileSectionOne = ({ professionalPersonalInformation }) => {
    return (
        <Card className="recommened-professional-profile-card" key={professionalPersonalInformation.id}>
            <div className="recommened-professional-profile-card-top">

                {/* <div className='avatar-name-location' style={{"border": "1px solid black"}}>
            professional image name and location
            </div>

            <div className="email-phonenumber">professional contact information</div>
            <div className="pricing-information">professional pricing information</div> */}

                <Avatar size={150} src={userProfilePhoto} className="recommened-professional-avatar" />
                <div className="recommened-professional-details">
                    <Title className='recommended-professional-name'>{professionalPersonalInformation.rep_business_name}</Title>
                    <Paragraph className='recommended-professional-location'>Lagos, Nigeria</Paragraph>
                </div>
                <div className="recommened-professional-contact">
                    <Title style={{ "fontSize": "24px", "fontWeight": "700" }}>Contact</Title>
                    <Paragraph><MailOutlined />  {professionalPersonalInformation.email}</Paragraph>
                    <Paragraph><PhoneOutlined /> +17648384094</Paragraph>
                </div>
                <div className="recommened-professional-pricing">
                    <Title style={{ "fontSize": "24px", "fontWeight": "700" }}>Price</Title>
                    <Paragraph>{professionalPersonalInformation.profile.compensation}</Paragraph>
                </div>
            </div>
            <div className="recommened-professional-profile-card-body">
                <Title style={{ "fontSize": "24px", "fontWeight": "700" }}>About Me</Title>
                <Paragraph style={{ "fontSize": "16px", "fontWeight": "300" }}>
                    {professionalPersonalInformation.profile.about_me}
                </Paragraph>
            </div>
        </Card>
    );
};

export default RecommendedProfessaionalProfileSectionOne;
