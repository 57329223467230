import React from 'react';
import logo from "../assets/company-logo.png";
import { Typography } from 'antd';
import '../css/header_component.css';

const { Title } = Typography;

const HeaderComponent = ({ headerData }) => {
    return (
        <header className="header">
            {/* <img src={logo} alt="Anvil Logo" className="logo" /> */}
            <Title level={3} className='companyHeader' style={{ fontWeight: 'bold' }}>{headerData.title}</Title>
            <p className='companyTagLine'>{headerData.subTitle}</p>
        </header>
    );
}

export default HeaderComponent;