import React, { useState, useEffect } from 'react';
import WelcomeMessage from './WelcomeMessage';
import TabsComponent from './TabsComponent';
import welcomeMessagesForDashboard from "../../assets/sampleStuffForDevelopmentAid/welcomeMessagesForDashboard.json"
import "../../css/main_screen_content_for_main_dashboard.css";

const tabSpecificDetails = welcomeMessagesForDashboard;

const MainScreeContent = ({ user_details = {} }) => {
    const [activeTab, setActiveTab] = useState('Reports');
    return (
        <div className="container">
            {/* <WelcomeMessage /> */}
            <WelcomeMessage title={tabSpecificDetails[activeTab].title} subtitle={tabSpecificDetails[activeTab].subtitle} />
            <div className='tabs-component-styling'>
                <TabsComponent activeTab={activeTab} setActiveTab={setActiveTab} user_details={user_details}/>
            </div>
        </div>
    );
}

export default MainScreeContent;
