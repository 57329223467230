import React, { useState } from 'react';
import "../../css/dropdownComponent.css";

const DropdownComponent = ({ dropdownOptions = {}, keyword = "", initialState = "", onValueChange = {} }) => {

    const [selectedOption, setSelectedOption] = useState(initialState);

    // useEffect(() => {
    //     console.log("Resetting state with initialState:", initialState);
    //     setSelectedOption(initialState);
    // }, [initialState]);

    const handleSelectChange = (event) => {
        const selectedValueFromTheDropdown = event.target.value;
        console.log("selectedValueFromTheDropdown :- ", selectedValueFromTheDropdown);
        setSelectedOption(selectedValueFromTheDropdown);
        onValueChange(selectedValueFromTheDropdown);
    };

    return (
        <div className="dropdown-container">
            <select className="ant-select" value={selectedOption} onChange={handleSelectChange}>
                <option value="" disabled>
                    Select {keyword}
                </option>
                {dropdownOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownComponent;
