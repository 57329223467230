import React from 'react';
import searchIcon from "../../assets/search.png";
import attachIcon from "../../assets/fileAttachIcon.png";
import sendButtonIcon from "../../assets/sendButton.png";
import '../../css/ChatUI.css';

const ChatUI = () => {
  return (
    <div className="chat-container">
      {/* Sidebar */}
      <div className="sidebar">

        {/* <div className="search-box">
          <input type="text" placeholder="Search" className="search-input" />
        </div> */}

        {/* you tube code search bar code */}
        <div className="userSearchSection">
          <div className="userSearchBar">
            <img src={searchIcon} alt="" />
            <input type="text" placeholder="Search" />
          </div>
        </div>


        <div className="chat-list">
          {/* Chat list items */}
          <div className="chat-item active">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>

          </div>
          {/* Repeating chat item */}
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>

          {/* Repeating chat item */}
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>

          {/* Repeating chat item */}
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>


          {/* Repeating chat item */}
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>
          <div className="chat-item">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="chat-info">
              <div className="chat-name">Teez Craft</div>
              <div className="chat-preview">Hi James, we are in...</div>
            </div>
            <div className='last-seen-details'>
              <div className="unread-days">1d</div>
              <div className="unread-count">3</div>
            </div>
          </div>

        </div>
      </div>

      {/* Chat Section */}
      <div className="chat-section">
        <div className="chat-messages">
          {/* Incoming message */}
          <div className="message incoming">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="message-content">
              <div className="message-name">Teez Craft</div>
              <div className="message-text">
                We are looking for marketing services to help us deliver on our next quarter goals. We are looking for marketing services to help us deliver on our next quarter goals.
              </div>
            </div>
          </div>

          {/* Outgoing message */}
          <div className="message outgoing">
            <div className="message-content">
              <div className="message-name">Mines Goals</div>
              <div className="message-text">
                We are looking for marketing services to help us deliver on our next quarter goals. We are looking for marketing services to help us deliver on our next quarter goals.
              </div>
            </div>
            <div className="avatar avatarForOutGoingMessage">MG</div>
          </div>

          {/* Incoming message */}
          <div className="message incoming">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="message-content">
              <div className="message-name">Teez Craft</div>
              <div className="message-text">
                We are looking for marketing services to help us deliver on our next quarter goals. We are looking for marketing services to help us deliver on our next quarter goals.
              </div>
            </div>
          </div>

          {/* Outgoing message */}
          <div className="message outgoing">
            <div className="message-content">
              <div className="message-name">Mines Goals</div>
              <div className="message-text">
                We are looking for marketing services to help us deliver on our next quarter goals. We are looking for marketing services to help us deliver on our next quarter goals.
              </div>
            </div>
            <div className="avatar avatarForOutGoingMessage">MG</div>
          </div>

          {/* Incoming message */}
          <div className="message incoming">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="message-content">
              <div className="message-name">Teez Craft</div>
              <div className="message-text">
                We are looking for marketing services to help us deliver on our next quarter goals. We are looking for marketing services to help us deliver on our next quarter goals.
              </div>
            </div>
          </div>

          {/* Outgoing message */}
          <div className="message outgoing">
            <div className="message-content">
              <div className="message-name">Mines Goals</div>
              <div className="message-text">
                We are looking for marketing services to help us deliver on our next quarter goals. We are looking for marketing services to help us deliver on our next quarter goals.
              </div>
            </div>
            <div className="avatar avatarForOutGoingMessage">MG</div>
          </div>

          {/* Incoming message */}
          <div className="message incoming">
            <div className="avatar avatarForOutIncomingMessage">TC</div>
            <div className="message-content">
              <div className="message-name">Teez Craft</div>
              <div className="message-text">
                We are looking for marketing services to help us deliver on our next quarter goals. We are looking for marketing services to help us deliver on our next quarter goals.
              </div>
            </div>
          </div>

          {/* Outgoing message */}
          <div className="message outgoing">
            <div className="message-content">
              <div className="message-name">Mines Goals</div>
              <div className="message-text">
                We are looking for marketing services to help us deliver on our next quarter goals. We are looking for marketing services to help us deliver on our next quarter goals.
              </div>
            </div>
            <div className="avatar avatarForOutGoingMessage">MG</div>
          </div>

        </div>



        {/* Message input */}
        <div className="message-input">
          <label htmlFor="file">
            <img src={attachIcon} alt="" />
          </label>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
          // onChange={handleImg}
          />
          <input type="text" placeholder="Your message" className="input-box" />
          <img src={sendButtonIcon} alt="" />
        </div>

        {/* <div className="bottomMessageInput">
          <div className="userSendMessageSection">
            <div className="messageBarSection">
              <label htmlFor="file">
                <img src={attachIcon} alt="" />
              </label>
              <input
                type="file"
                id="file"
                style={{ display: "none" }}
              // onChange={handleImg}
              />
              <input type="text" placeholder="Type your message here" />
              <img src={sendButtonIcon} alt="" />
            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default ChatUI;
