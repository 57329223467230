import React from 'react';
import CommonYellowBackgroundColorButton from "./CommonYellowBackgroundColorButton";
import CommonLearnButton from './CommonLearnButton';
import "../css/card_button_group.css";

const CardButtonGroup = ({ cardActionButtonTitle = "Start", isButtonEnabled = true, startButtonNavigationPath = "", user_details={} }) => {
    return (
        <div className="button-group">
            <CommonLearnButton />
            <CommonYellowBackgroundColorButton
                cardActionButtonTitle={cardActionButtonTitle}
                isButtonEnabled={isButtonEnabled}
                startButtonNavigationPath={startButtonNavigationPath}
                user_details={user_details}
            />
        </div>
    );
}

export default CardButtonGroup;
