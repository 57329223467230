import React from 'react';
import '../css/assessmentFormHeaderSectionInfo.css'; // Import the custom CSS file

const AssessmentFormHeaderSectionInfo = ({sectionNumber, sectionName , totalNumberOfSections}) => {
    return (
        <div className="section-info">
            <div className="section-name">{sectionName}</div>
            <div className="section-progress">Section {sectionNumber}/{totalNumberOfSections}</div>
        </div>
    );
};

export default AssessmentFormHeaderSectionInfo;
